import React from 'react';
import { useRoutes } from 'react-router-dom';

import Dashboard from '$src/containers/Dashboard';
import Home from '$src/containers/Home';

const routes = [
  {
    path: '/',
    element: <Dashboard />,
    children: [{ index: true, element: <Home /> }],
  },
];

function Routes() {
  const element = useRoutes(routes);
  return element;
}

export default Routes;
