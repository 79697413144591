import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './style.less';

function Dashboard() {
  return (
    <section className={styles.container}>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </section>
  );
}

export default Dashboard;
